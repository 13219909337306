import { generalRoutes } from "./routesConstants";

import userRoles from "../constants/userRoles";

function useAppMenuRoutes() {
  const adminMenuRoutes = [
    {
      id: userRoles.PREVIEW_USERS,
      title: "Istoric AWB",
      path: `/`,
    },
    {
      id: userRoles.CREATE_USER,
      title: "Creeaza AWB",
      path: `/${generalRoutes.createAwb}`,
    },
    {
      id: userRoles.CREATE_USER,
      title: "Creeaza BIN",
      path: `/${generalRoutes.createBin}`,
    },
  ];

  return adminMenuRoutes;
}

export default useAppMenuRoutes;
