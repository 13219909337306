export const initialMainRoute = "dashboard";

export const crudRoutes = {
  create: "creaza",
  edit: "edit",
};

export const generalRoutes = {
  createAwb: "creeaza-awb",
  createBin: "creeaza-bin",
};
