import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUser } from "../api/user.api";
import { StateUserInterface } from "../interfaces/user.interface";

export const defaultStateUser: StateUserInterface = {
  data: {
    id_client: null,
    auth_key: "",
    regcom: "",
    cui: "",
    adresa: "",
    localitate: "",
    judet: "",
    cod_postal: null,
    cont: "",
    banca: "",
    email_client: "",
    pers_client: "",
    tel_client: null,
    ts_inreg: "",
    username: "",
  },
  isLoadingUser: false,
  isErrorUser: false,
  errorMessageUser: "",
};

const user = createSlice({
  name: "user",
  initialState: defaultStateUser,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        token: string;
      }>,
    ) {
      return {
        ...state,
        isLoadingUser: false,
        token: action.payload.token,
      };
    },
    removeUser() {
      return { ...defaultStateUser, isLoadingUser: false };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchUser.pending, () => {
      return {
        ...defaultStateUser,
        isLoadingUser: true,
        errorMessageUser: "",
        isErrorUser: false,
      };
    });
    builder.addCase(fetchUser.rejected, (state, action: any) => {
      return {
        ...state,
        isLoadingUser: false,
        errorMessageUser: action.payload?.message,
        isErrorUser: true,
      };
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      return {
        ...state,
        isLoadingUser: false,
        data: action.payload,
      };
    });
  },
});

export const { setUser, removeUser } = user.actions;

export default user.reducer;
