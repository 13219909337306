import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import appReducer from "../reducer/app.reducer";

import generalReducer from "../reducer/general.reducer";
import globalComponentsReducer from "../reducer/globalComponents.reducer";

import authReducer from "../reducer/auth.reducer";
import userReducer from "../reducer/user.reducer";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appConfig: appReducer,
    user: userReducer,
    general: generalReducer,
    globalComponents: globalComponentsReducer,
  },
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => {
    if (isDebuggerMode) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
