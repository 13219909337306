import { generalRoutes } from "./routesConstants";

import userRoles from "../constants/userRoles";

import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import CreateAwb from "../pages/CreateAwb";
import CreateBin from "../pages/CreateBin";

const errorRoutes = [
  {
    id: "Error",
    path: "error/*",
    element: <ErrorsPage />,
  },
  {
    id: "Error500",
    path: "/error/500",
    element: <Error500 />,
  },
  {
    id: "Error404",
    path: "*",
    element: <Error404 />,
  },
];

const general = [
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${generalRoutes.createAwb}`,
    element: <CreateAwb />,
  },
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${generalRoutes.createBin}`,
    element: <CreateBin />,
  },
];

function useAppRoutes() {
  return {
    errorRoutes,
    allRoutes: general,

    general: general,
  };
}

export default useAppRoutes;
