import React from "react";
import { TablePagination as MUITablePagination } from "@mui/material";
import useTableCtx from "../tableContext/useTableCtx";

function TablePagination({ totalItems }: { totalItems: number | undefined }) {
  const { tablePagination, setTablePagination } = useTableCtx()!;

  const handleChangePage = (event: unknown, newPage: number) => {
    setTablePagination((prev) => {
      return {
        ...prev,
        page: newPage + 1,
      };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTablePagination((prev) => {
      return {
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
      };
    });
  };

  return (
    <MUITablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={totalItems ?? 1}
      rowsPerPage={tablePagination.rowsPerPage}
      page={tablePagination.page - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={<span className="mb-0">Randuri:</span>}
      labelDisplayedRows={({ page }: { page: number }) => {
        return <span className="mb-0">Pagina: {page + 1}</span>;
      }}
      backIconButtonProps={{
        color: "primary",
      }}
      nextIconButtonProps={{ color: "primary" }}
      SelectProps={{
        inputProps: {
          "aria-label": "page number",
        },
      }}
      showFirstButton={true}
      showLastButton={true}
    />
  );
}

export default TablePagination;
