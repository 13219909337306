import { useNavigate } from "react-router-dom";
import { ListItem, ListItemButton } from "@mui/material";
import useAppMenuRoutes from "../useAppMenuRoutes";
import SvgIcon from "../../helpers/SvgIcon";
import Text from "../../styleguide/Text";

const DrawerItems = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const navigate = useNavigate();
  const categories = useAppMenuRoutes();

  const handleNavigate = (path: string) => {
    if (handleDrawerClose) {
      // handleDrawerClose();
    }
    navigate(path);
  };

  return (
    <div>
      {categories.map((item, index) => {
        const { title, path } = item || {};
        return (
          <div key={index}>
            <ListItem
              key={index}
              disablePadding
              onClick={() => handleNavigate(path)}>
              <ListItemButton>
                <SvgIcon type="DOUBLE_ARROW_RIGHT" className="svg-icon-5" />
                <Text variant="body1" className="fw-bolder p-1">
                  {title}
                </Text>
              </ListItemButton>
            </ListItem>
          </div>
        );
      })}
    </div>
  );
};

export default DrawerItems;
