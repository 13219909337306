import { useState } from "react";
import { fetchAwbHistory, fetchBinHistory } from "../../redux/api/general.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { generalRoutes } from "../../router/routesConstants";

import CustomButton from "../../components/CustomButton";
import ContentCard from "../../components/misc/ContentCard";
import FormHeader from "../../components/misc/FormHeader";
import { TableWidget } from "../../components/tables/TableWidget";
import { TableActions } from "../../components/tables/tableContext/TableContext";

import {
  awbHistoryTableHeader,
  getAwbTableData,
} from "./components/tableConfigs/awbHistoryTableConfig";
import {
  binHistoryTableHeader,
  getBinTableData,
} from "./components/tableConfigs/binHistoryTableConfig";

function Home() {
  const dispatch = useAppDispatch();

  const [selectedType, setSelectedType] = useState<"awb" | "bin">("awb");

  const {
    general: {
      awbHistory: {
        data: { data: dataAwb, total: totalAwb },
        isLoading: isLoadingAwb,
      },
      binHistory: {
        data: { data: dataBin, total: totalBin },
        isLoading: isLoadingBin,
      },
    },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  const handleAwbTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchAwbHistory({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  const handleBinTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchBinHistory({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <>
      <ContentCard cardBodyClassName="d-flex">
        <CustomButton
          variant={selectedType === "awb" ? "contained" : "outlined"}
          style={{ borderEndEndRadius: 0, borderStartEndRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("awb")}>
          AWB
        </CustomButton>
        <CustomButton
          variant={selectedType === "bin" ? "contained" : "outlined"}
          style={{ borderEndStartRadius: 0, borderStartStartRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("bin")}>
          BIN
        </CustomButton>
      </ContentCard>
      {selectedType === "awb" && (
        <TableWidget
          CustomHeaderComponent={() => (
            <FormHeader
              title="Istoric AWB-uri"
              buttonIcon="ADD"
              buttonRoute={`/${generalRoutes.createAwb}`}
              buttonTooltip="Creeaza AWB"
            />
          )}
          tableHeaderData={awbHistoryTableHeader}
          tableItemsData={getAwbTableData(dataAwb)}
          totalItems={totalAwb}
          tableLoading={isLoadingAwb}
          handleTableActions={handleAwbTableActions}
          borderedRow
        />
      )}

      {selectedType === "bin" && (
        <TableWidget
          CustomHeaderComponent={() => (
            <FormHeader
              title="Istoric BIN-uri"
              buttonIcon="ADD"
              buttonRoute={`/${generalRoutes.createBin}`}
              buttonTooltip="Creeaza BIN"
            />
          )}
          tableHeaderData={binHistoryTableHeader}
          tableItemsData={getBinTableData(dataBin)}
          totalItems={totalBin}
          tableLoading={isLoadingBin}
          handleTableActions={handleBinTableActions}
          borderedRow
        />
      )}
    </>
  );
}

export default Home;
