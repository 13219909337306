import React, { useCallback, useEffect, useState } from "react";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

function useTableConfig({
  tableSearch,
  tableFilter,
  tablePagination,
  setTablePagination,
  setTableFilter,
  setTableSearch,
}: {
  tableSearch: object | null;
  setTableSearch: React.Dispatch<React.SetStateAction<object | null>>;
  tableFilter: object | null;
  setTableFilter: React.Dispatch<React.SetStateAction<object | null>>;
  tablePagination: { page: number; rowsPerPage: number };
  setTablePagination: React.Dispatch<
    React.SetStateAction<{ page: number; rowsPerPage: number }>
  >;
}) {
  const { routeParams, handleSetQuerryParams } = useRoutesHelper();

  const [initialRender, setInitialRender] = useState(true);
  const [debouncedTableContextValues, setDebouncedTableContextValues] =
    useState<any>(null);

  const handleTableRouteParams = useCallback(
    (pageParams: any) => {
      if (pageParams?.page || pageParams?.rowsPerPage) {
        setTablePagination((prev) => ({
          ...prev,
          page: pageParams.page ? parseInt(pageParams.page) : prev.page,
          rowsPerPage: pageParams.rowsPerPage
            ? parseInt(pageParams.rowsPerPage)
            : prev.rowsPerPage,
        }));
      }
      setTableFilter((prev) => ({ ...prev, ...pageParams }));
      setTableSearch((prev) => ({ ...prev, ...pageParams }));
    },
    [setTableFilter, setTablePagination, setTableSearch],
  );

  // on the initial reander, we wan't to check route params and complete the table context
  useEffect(() => {
    if (initialRender) {
      if (routeParams.page) {
        handleTableRouteParams(routeParams);
        setInitialRender(false);
      }
      return;
    }
  }, [handleTableRouteParams, initialRender, routeParams]);

  // we update the route params with the table context values
  useEffect(() => {
    if (tablePagination) {
      handleSetQuerryParams({
        ...tableSearch,
        ...tableFilter,
        ...tablePagination,
      });
    }
  }, [handleSetQuerryParams, tablePagination, tableFilter, tableSearch]);

  // we debounce the table context values to avoid multiple requests
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTableContextValues({
        tableSearch,
        tableFilter,
        tablePagination,
      });
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [tablePagination, tableSearch, tableFilter]);

  return {
    debouncedTableContextValues,
  };
}

export default useTableConfig;
