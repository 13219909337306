import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import { appWasLoaded } from "../reducer/app.reducer";
import { removeUserToken } from "../reducer/auth.reducer";

export const fetchUser = createAsyncThunk(
  "/client",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/client", "POST");
      dispatch(appWasLoaded());
      localStorage.setItem("auth_key", data?.auth_key);

      return data;
    } catch (err: any) {
      dispatch(appWasLoaded());
      dispatch(removeUserToken());
      console.warn("/client", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
