import React from "react";
import Text from "../../styleguide/Text";
import CustomButton from "../CustomButton";
import SvgIcon, { SvgIconType } from "../../helpers/SvgIcon";

function FormHeader({
  title,
  buttonRoute,
  buttonIcon,
  buttonTooltip = "",
}: {
  title: string;
  buttonRoute: string;
  buttonIcon: SvgIconType;
  buttonTooltip?: string;
}) {
  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <Text
        variant="h3"
        component="h3"
        className="card-title mb-4"
        gutterBottom>
        {title}
      </Text>
      {buttonIcon && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle={buttonTooltip}
          navigate={buttonRoute}>
          <SvgIcon type={buttonIcon} />
        </CustomButton>
      )}
    </div>
  );
}

export default FormHeader;
